<div class="side-menu-spacer" [ngClass]="{ 'side-menu-closed': sideMenuClosed }"></div>
<div class="side-menu" [ngClass]="{ 'side-menu-closed': sideMenuClosed }">
    <div class="menu-container">
        <div class="profile-image-container">
            <img class="profile-image" src="../../assets/images/profile.jpg">
        </div>
        <div class="nav-links-container">
            <div *ngFor="let navLink of navLinks">
                <div [ngSwitch]="navLink.special" class="nav-link">
                    <ng-container *ngSwitchCase="'toggleDark'">
                        <div *ngIf="!darkTheme" class="toggleDark" (click)="toggleDark()">
                            <p>{{ navLink.text }}</p>
                            <mat-icon *ngIf="!darkTheme" svgIcon="{{ navLink.icon }}"></mat-icon>
                        </div>
                        <div *ngIf="darkTheme" class="toggleDark" (click)="toggleDark()">
                            <p>{{ navLink.text2 }}</p>
                            <mat-icon *ngIf="darkTheme" svgIcon="{{ navLink.icon2 }}"></mat-icon>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <a href="{{ navLink.href }}{{darkTheme && navLink.keepTheme ? '?theme=dark' : ''}}" target="{{ navLink.tab ? '_blank' : undefined}}">
                            <p>{{ navLink.text }}</p>
                            <mat-icon svgIcon="{{ navLink.icon }}"></mat-icon>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="menu-opener-container">
        <div class="menu-opener" (click)="menuOpenerClick()">
            <mat-icon *ngIf="sideMenuClosed" svgIcon="chevron-right"></mat-icon>
            <mat-icon *ngIf="!sideMenuClosed" svgIcon="chevron-left"></mat-icon>
        </div>
    </div>
</div>

<div class="icon-menu" [ngClass]="{ 'icon-menu-closed': iconMenuClosed }">
    <div class="nav-icon-container">
        <div *ngFor="let navLink of navLinks; index as i">
            <div [ngSwitch]="navLink.special" class="nav-icon nav-icon-link nav-icon-button"
                [style.transform] = "calcTranslate(i)">
                <ng-container *ngSwitchCase="'toggleDark'">
                    <div *ngIf="!darkTheme" class="toggleDark" (click)="toggleDark()">
                        <span class="icon-tooltip"> {{ navLink.text }} </span>
                        <mat-icon svgIcon="{{ navLink.icon }}"></mat-icon> 
                    </div>
                    <div *ngIf="darkTheme" class="toggleDark" (click)="toggleDark()">
                        <span class="icon-tooltip"> {{ navLink.text2 }} </span>
                        <mat-icon svgIcon="{{ navLink.icon2 }}"></mat-icon> 
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <a href="{{ navLink.href }}{{darkTheme && navLink.keepTheme ? '?theme=dark' : ''}}" target="{{ navLink.tab ? '_blank' : undefined}}">
                        <span class="icon-tooltip"> {{ navLink.text }} </span>
                        <mat-icon svgIcon="{{ navLink.icon }}"></mat-icon> 
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="icon-menu-container nav-icon-button" (click)="iconMenuOpenerClick()">
        <div class="icon-menu-opener" [ngClass]="{ 'icon-menu-closed': iconMenuClosed }"></div>
    </div>
</div>