<div class="not-found">
    <menu-component></menu-component>
    <div class="content">
        <div class="header">
            <h1>404 ERROR</h1>
            <h3>Oh no! Looks like your <span>lost</span>.</h3>
            <p>The page you requested could not be found.</p>
        </div>

        <button (click)="navigateHome()">
            <span><mat-icon svgIcon="account-box-outline"></mat-icon> Back Home</span>
        </button>

        <div class="footer-spacing"></div>
    </div>
</div>
